<template>
  <div class="animated fadeIn">
    <div>
      <h4 class="mb-3">
        Доверенность ID{{ attorneyId }}&ensp;
        <span
          v-if="form.status === 0"
          class="text-secondary"
        >(На рассмотрении)</span>
        <span
          v-if="form.status === 1"
          class="text-success"
        >(Действует)</span>
        <span
          v-if="form.status === 2"
          class="text-danger"
        >(Аннулирована)</span>
      </h4>
      <div class="form-sections">
        <div class="custom-fieldset">
          <b-row>
            <b-col md="6">
              <b-form-group
                id="attorneyNumberInputGroup"
                label="Номер доверенности"
                label-for="attorney-number"
              >
                <b-form-input
                  id="attorney-number"
                  v-model.trim="form.number"
                  type="text"
                  name="attorney-number"
                  autocomplete="attorney-number"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="attorneyDateInputGroup"
                label="Дата доверенности"
                label-for="attorney-date"
              >
                <el-date-picker-input
                  id="attorney-date"
                  v-model.trim="form.date"
                  type="date"
                  name="attorney-date"
                  autocomplete="attorney-date"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-card no-body>
                <b-card-header>
                  Водители
                </b-card-header>
                <b-card-body :class="{'loading' : loading}">
                  <v-client-table
                    v-if="drivers.items"
                    id="dataTable"
                    :data="drivers.items"
                    :columns="drivers.columns"
                    :options="drivers.options"
                    :theme="drivers.theme"
                  >
                    <span
                      slot="driver.status"
                      slot-scope="props"
                    >
                      <span
                        v-if="props.row.driver.status === 0"
                        class="text-secondary"
                      > Заблокирован СБ </span>
                      <span
                        v-if="props.row.driver.status === 1"
                        class="text-success"
                      > Допущен </span>
                      <span
                        v-if="props.row.driver.status === 2"
                        class="text-primary"
                      > На верификации </span>
                      <span
                        v-if="props.row.driver.status === 3"
                        class="text-danger"
                      > Не допущен </span>
                      <span
                        v-if="props.row.driver.status === 4"
                        class="text-primary"
                      > Проверка логистом </span>
                      <span
                        v-if="props.row.driver.status === 5"
                        class="text-danger"
                      > Отклонен </span>&ensp;
                      <span
                        v-if="props.row.driver.blockedSecurity"
                        class="badge badge-danger"
                      >Блокировка СБ</span>
                    </span>
                    <span
                      slot="driver.passportIssueDate"
                      slot-scope="props"
                    >{{ formatDatetime(props.row.driver.passportIssueDate) }}</span>
                    <span
                      slot="driver.passportScreenFirst"
                      slot-scope="props"
                    >
                      <span v-if="props.row.passportScreenFirst">
                        <p v-if="props.row.passportScreenFirst.file">
                          <a
                            :href="'/files/' + props.row.passportScreenFirst.file.guid"
                            target="_blank"
                            @click.prevent="downloadFile(props.row.passportScreenFirst.file)"
                          >Скачать</a>
                        </p>
                      </span>
                      <span v-else>Н/Д</span>
                    </span>
                    <span
                      slot="driver.passportScreenSecond"
                      slot-scope="props"
                    >
                      <span v-if="props.row.passportScreenSecond">
                        <p v-if="props.row.passportScreenSecond.file">
                          <a
                            :href="'/files/' + props.row.passportScreenSecond.file.guid"
                            target="_blank"
                            @click.prevent="downloadFile(props.row.passportScreenSecond.file)"
                          >Скачать</a>
                        </p>
                      </span>
                      <span v-else>Н/Д</span>
                    </span>
                    <span
                      slot="contractFile"
                      slot-scope="props"
                    >
                      <span v-if="props.row.contractFiles.length > 0">
                        <p
                          v-for="(file, index) in props.row.contractFiles"
                          :key="`file-${index}`"
                        >
                          <a
                            :href="'/files/' + file.file.guid"
                            target="_blank"
                            @click.prevent="downloadFile(file.file)"
                          >Скачать</a>
                        </p>
                      </span>
                      <span v-else>Н/Д</span>
                    </span>
                  </v-client-table>
                </b-card-body>
              </b-card>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="attorneyDateStartInputGroup"
                label="Период действия с"
                label-for="attorney-date-start"
              >
                <el-date-picker-input
                  id="attorney-date-start"
                  v-model.trim="form.dateStart"
                  type="date"
                  name="attorney-date-start"
                  autocomplete="attorney-date-start"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="attorneyDateEndInputGroup"
                label="Период действия по"
                label-for="attorney-date-end"
              >
                <el-date-picker-input
                  id="attorney-date-end"
                  v-model.trim="form.dateEnd"
                  type="date"
                  name="attorney-date-end"
                  autocomplete="attorney-date-end"
                  format="dd.MM.yyyy"
                  :picker-options="pickerOptions"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="form.cancelComment"
              md="6"
            >
              <b-form-group
                id="attorneyDateEndInputGroup"
                label="Причина аннулирования"
              >
                <b-form-textarea
                  v-model="form.cancelComment"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                id="attorneyInputGroup"
                label="Доверенность"
                label-for="attorney"
              >
                <div
                  v-if="form.file.file"
                  class="mt-2"
                >
                  Загруженный файл:
                  <a
                    :href="'/files/' + form.file.file.guid"
                    target="_blank"
                    @click.prevent="downloadFile(form.file.file)"
                  >{{ form.file.file.originalName }}</a>
                </div>
                <div
                  v-else
                  class="mt-2"
                >
                  Файл не добавлен
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
    <div class="fixed-buttons">
      <b-button
        type="button"
        variant="secondary"
        class="mr-2"
        :class="{'loading' : loading}"
        @click="$router.go(-1)"
      >
        Назад
      </b-button>
      <b-button
        v-if="$store.state.user.ownerType === 'contractor' && form.status === 0 && $store.getters.isAdmin"
        type="button"
        variant="primary"
        class="mr-2"
        :class="{'loading' : loading}"
        :disabled="loading"
        :href="`/attorneys/attorney/id${attorneyId}`"
      >
        Редактировать
      </b-button>
      <b-button
        v-if="$store.state.user.ownerType === 'customer' && form.status === 0 && $store.getters.isAdmin"
        type="button"
        variant="primary"
        class="mr-2"
        :class="{'loading' : loading}"
        :disabled="loading"
        :href="`/contractors/id${contractorId}/attorney/id${attorneyId}/edit`"
      >
        Редактировать
      </b-button>
      <!--b-button
        v-if="form.status === 0 && $store.getters.isAdmin"
        type="button"
        variant="danger"
        class="mr-2"
        @click="deleteAttorney()"
      >Удалить</b-button-->
      <b-button
        v-if="$store.state.user.ownerType === 'customer' && form.status !== 1 && $store.getters.isAdminOrManager"
        type="button"
        variant="success"
        class="mr-2"
        :class="{'loading' : loading}"
        :disabled="loading"
        @click="verifyAttorney()"
      >
        Верифицировать
      </b-button>
      <b-button
        v-if="$store.state.user.ownerType === 'customer' && form.status !== 2 && $store.getters.isAdminOrManager"
        type="button"
        variant="danger"
        :class="{'loading' : loading}"
        :disabled="loading"
        @click="showCancelAttorneyModal()"
      >
        Аннулировать
      </b-button>
    </div>
    <b-modal
      v-model="attorneyModal.show"
      class="modal-warning"
      ok-variant="success"
      ok-title="Принять"
      cancel-title="Назад"
      :title="'Довереность '+attorneyModal.attorney.number"
      @ok="cancelAttorney()"
    >
      <b-form-group
        class="form-group-el-select"
        label="Причина аннулирования"
      >
        <b-form-textarea v-model="attorneyModal.attorney.cancelComment" />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
import ElDatePickerInput from '../../components/ElDatePickerInput.vue';
import notifications from '../../components/mixins/notifications';
import {
  customersContractorsAttorneyRead,
  contractorsAttorneyRead,
  contractorsAttorneyDelete,
  contractorsAttorneyUncancel,
  contractorsAttorneyCancel,
  downloadFile,
} from '../../services/api';
import moment from 'moment';
import tz from 'moment-timezone';
import {Event, ServerTable} from 'vue-tables-2';
import ElSelectClearable from '@/components/ElSelectClearable';

export default {
  name: 'AttorneysView',
  components: {
    ServerTable,
    Event,
    ElSelectClearable,
    ElDatePickerInput,
  },
  mixins: [notifications],
  props: ['contractorId', 'attorneyId'],
  data() {
    return {
      attorneyModal: {
        show: false,
        attorney: {
          name: '',
          cancelComment: '',
        },
      },
      form: {
        status: null,
        drivers: {
          options: [],
          value: [],
        },
        number: '',
        date: '',
        dateStart: '',
        dateEnd: '',
        file: {},
      },
      data: {},
      drivers: {
        columns: [
          'driver.fullname',
          'driver.status',
          'driver.passportSeries',
          'driver.passportNumber',
          'driver.passportIssueDate',
          'driver.passportIssuedBy',
          'driver.passportScreenFirst',
          'driver.passportScreenSecond',
          'contractFile',
        ],
        options: {
          headings: {
            'driver.fullname': 'ФИО',
            'driver.status': 'Статус',
            'driver.passportSeries': 'Серия',
            'driver.passportNumber': 'Номер',
            'driver.passportIssueDate': 'Дата выдачи паспорта',
            'driver.passportIssuedBy': 'Кем выдан',
            'driver.passportScreenFirst': 'Главная страница паспорта',
            'driver.passportScreenSecond': 'Страница с пропиской',
            'contractFile': 'Документы договорных отношений водителя и работодателя',
          },
          sortable: ['driver.fullname'],
          filterable: ['driver.fullname'],
          sortIcon: {base: 'fa', up: 'fa-sort-asc', down: 'fa-sort-desc', is: 'fa-sort'},
          pagination: {
            chunk: 10,
            edge: true,
            nav: 'fixed',
          },
          perPage: 5,
          perPageValues: [5, 10, 25],
          texts: {
            count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
            first: 'Первая',
            last: 'Последняя',
            filter: 'Фильтр:',
            filterPlaceholder: 'Поисковый запрос',
            limit: 'Записи:',
            page: 'Страница:',
            noResults: 'Ни одного водителя не добавлено',
            filterBy: 'Фильтр по {column}',
            loading: 'Загрузка...',
            defaultOption: 'Выбор {column}',
            columns: 'Столбцы',
          },
        },
        items: [],
        theme: 'bootstrap4',
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      loading: false,
    };
  },
  watch: {
    '$route'() {
      this.contractorsAttorneyRead(this.contractorId, this.attorneyId);
    },
  },
  mounted() {
    this.contractorsAttorneyRead(this.contractorId, this.attorneyId);
  },
  methods: {
    downloadFile,
    async contractorsAttorneyRead(contractorId, attorneyId) {
      this.loading = true;
      let response;
      if (this.$store.state.user.ownerType === 'contractor') {
        response = await contractorsAttorneyRead(contractorId, attorneyId);
      } else if (this.$store.state.user.ownerType === 'customer') {
        response = await customersContractorsAttorneyRead(contractorId, attorneyId);
      }
      this.attorneyModal.attorney = response.data;
      if (response && response.status === 200) {
        this.form.status = response.data.status;
        if (response.data.contractorDrivers) {
          this.drivers.items = response.data.contractorDrivers.map((contractorDriver) => {
            contractorDriver.driver.fullname = contractorDriver.driver.surname + ' ' + contractorDriver.driver.name;
            if (contractorDriver.driver.patronymic) {
              contractorDriver.driver.fullname = contractorDriver.driver.fullname + ' ' + contractorDriver.driver.patronymic;
            }
            return contractorDriver;
          });
        }
        this.form.number = response.data.number;
        this.form.cancelComment = response.data.cancelComment;
        this.form.date = response.data.date;
        this.form.dateStart = response.data.dateStart;
        this.form.dateEnd = response.data.dateEnd;
        this.attorneyModal.attorney.date = moment(response.data.date).format('YYYY-MM-DD');
        this.attorneyModal.attorney.dateStart = moment(response.data.dateStart).format('YYYY-MM-DD');
        this.attorneyModal.attorney.dateEnd = moment(response.data.dateEnd).format('YYYY-MM-DD');
        if (response.data.file) {
          this.form.file = response.data.file;
        }
      }
      this.loading = false;
    },
    formatDatetime(date) {
      return date ? moment(date).format('DD.MM.YYYY') : '';
    },
    async deleteAttorney() {
      this.loading = true;
      const response = await contractorsAttorneyDelete(this.contractorId, this.attorneyId);
      if (response && response.status === 200) {
        this.showSuccess('Доверенность удалена');
        this.$router.push({path: '/attorneys/customer'});
      }
      this.loading = false;
    },
    async verifyAttorney() {
      this.loading = true;
      const response = await contractorsAttorneyUncancel(this.contractorId, this.attorneyId);
      if (response && response.status === 200) {
        this.showSuccess('Доверенность верифицирована');
        this.$router.push({path: '/attorneys/customer'});
      }
      this.loading = false;
    },
    async showCancelAttorneyModal() {
      this.attorneyModal.show = true;
    },
    async cancelAttorney() {
      this.loading = true;
      const response = await contractorsAttorneyCancel(
        this.attorneyModal.attorney.contractor.id,
        this.attorneyModal.attorney.id,
        this.attorneyModal.attorney,
      );
      if (response && response.status === 200) {
        this.showSuccess('Доверенность аннулирована');
        this.$router.push({path: '/attorneys/customer'});
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
</style>

